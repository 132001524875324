@import '../../variables';

.homepage {
    width: 100vw;
    background-image: url('../../assets/mind-right-background.svg');
    background-size: cover;

    main {
        position: absolute;
        top: 15%;
        left: 15%;
        height: 70%;
        width: 70%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 2rem;
        box-sizing: border-box;

        .name {
            color: $color-black;
            font-size: 10rem;
            margin: 0;
            padding-top: 6rem;
            width: fit-content;
        }
    
        .buttons {
            display: flex;
            justify-content: center;
            margin: 8rem;
    
            & > button {
                margin: 0 3rem;
            }
        }

        .logged-out-message {
            font-size: 2.4rem;
            text-align: center;
            padding-bottom: 1rem;
            border-bottom: 2px solid $color-mint-dark;
        }

        .overlay {
            position: absolute;
            height: 100vh;
            width: 100vw;
            background-color: rgba($color: black, $alpha: .5);
        }
    }

    .tile {
        position: absolute;
        pointer-events: none;
    
        &-center {
            top: 15%;
            left: 15%;
            height: 70%;
            width: 70%;
            box-shadow: 0rem 0rem 1rem 1rem rgba(black, .2);
        }

        &-top-left {
            top: 0;
            left: 0;
            height: 50%;
            width: 15%;
            box-shadow: -1rem 1rem 1rem rgba(black, .2);
        }
                
        &-top-middle {
            top: 0;
            left: 30%;
            height: 15%;
            width: 44%;
            box-shadow: 0rem -2rem 1rem 1rem rgba(black, .2);
        }
    
        &-bottom-left {
            top: 85%;
            left: 0;
            height: 15%;
            width: 60%;
            box-shadow: 1rem 1rem 1rem rgba(black, .2);
        }
    
        &-bottom-right {
            top: 70%;
            left: 85%;
            height: 30%;
            width: 15%;
            box-shadow: 1rem -1rem 1rem rgba(black, .2);
        }
    }
}

@media screen and (max-width: 720px) {
    .homepage {

        main {
            left: 0;
            width: 100%;

            .name {
                font-size: 8rem;
                margin-bottom: 4rem;
                padding-top: 0;

            }
            
            .buttons {
                justify-content: space-around;
                align-items: center;
                width: 100%;
                margin: 0;
    
                & > button {
                    margin: 2rem 0;
                }
            }
        }

        
        .tile {
            
            &-center {
                left: 0%;
                width: 100%;
            }
            
            &-top-left {
                display: none;
            }
            
            &-bottom-right {
                display: none;
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .homepage {

        main {

            .name {
                font-size: 6rem;
            }
            
            .buttons {
                flex-direction: column;
            }

            .overlay {
                display: none;
            }
        }
    }
}