.menu-bar {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 2rem;
    box-sizing: border-box;
    z-index: 999;

    .menu-button {
        background: none;
        border: none;
        padding: .5rem;
        cursor: pointer;
    }
}