.random-thought {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    background-size: cover;
    background-position: center;

    .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-size: cover;
        background-position: center;
        transition: opacity .5s;

        &-1 {
            background-image: url('../../assets/beach-image.jpeg');
            opacity: 1;
        }
    
        &-2 {
            opacity: 0;
        }
    }

    .text {
        z-index: 99;
        width: 80%;
        height: 70%;
        margin: auto;
        font-size: 4rem;
        line-height: 1.75;
        text-align: center;
        text-shadow: 0 0 .5rem white, 0 0 .5rem white, 0 0 .5rem white;
        overflow: scroll;
    }

    .shuffle-button {
        position: absolute;
        bottom: 5%;
    }
}