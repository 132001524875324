@import '../../variables';

.login {
    position: absolute;
    z-index: 10;
    height: 72vh;
    width: 72vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    border-radius: .5rem;
    background-image: url('../../assets/login-background.svg');
    background-size: cover;
    background-position: center;

    .title {
        font-size: 3.6rem;
        margin-bottom: 4rem;
    }

    form {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        width: 50%;

        & input {
            background: white;
            border-radius: .5rem;
            border: none;
            margin-bottom: 2rem;
            padding: 1rem;
            width: 100%;
            font-size: 1.6rem;
            box-sizing: border-box;
        }

        & button {
            margin-top: 2rem;
        }
    }

    .error-message {
        position: absolute;
        bottom: 10%;
        width: 50%;
        background-color: rgba(#ff5454, .7);
        color: white;
        text-align: center;
        font-size: 1.6rem;
        padding: 2rem;
        border-radius: .5rem;
    }

    .close {
        @include icon-button;
        position: absolute;
        top: 0;
        right: 0;

        &-icon {
            @include icon;
        }
    }
}

@media screen and (max-width: 720px) {
    .login {
        left: 0%;
        width: 100vw;

        form {
            width: 80%;
        }
    }
}

@media screen and (max-width: 480px) {
    .login {
        height: 100%;
        justify-content: flex-start;

        .title {
            margin-top: 25%;
        }
    }
}