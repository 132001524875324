@import '../../variables';

.thoughts-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 8rem;
    padding-bottom: 2rem;
    margin: 0 15%;

    .buttons {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        width: 100%;
    }
}

@media screen and (max-width: 1000px) {
    .thoughts-list {
        margin: 0 10%;
    }
}

@media screen and (max-width: 700px) {
    .thoughts-list {
        margin: 0 5%;
    }
}

@media screen and (max-width: 480px) {
    .thoughts-list {

        .buttons {
            flex-direction: column;
            align-items: center;

            & > button {
                margin: 1rem 0;
            }
        }
    }
}