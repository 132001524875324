@import '../../variables';

// Horizontal
$menu-start: 2rem;
$menu-width-large: 30rem;
$menu-width-small: 20rem;
$menu-end-large: $menu-start + $menu-width-large;
$menu-end-small: $menu-start + $menu-width-small;
$sub-menu-right-margin: 2rem;

// Vertical
$menubar-height: 10rem;
$menu-item-height: 4rem;
$categories-top: calc($menubar-height + $menu-item-height  + $menu-item-height);

.submenu {
    position: absolute;
    top: 0;
    left: 100%;
    min-height: 100%;
    box-sizing: border-box;
    min-width: 100%;
    width: calc(100vw - $menu-end-large - $menu-end-large);
    background-color: white;
    color: black;
    border: 2px solid $color-mint-dark;
    border-radius: 0 .5rem .5rem .5rem;
    max-height: 50vh;
}

@media screen and (max-width: 1200px) {
    .submenu {
        width: calc(100vw - $menu-end-large - $menu-start);
    }
}

@media screen and (max-width: 650px) {
    .submenu {
        width: calc(100vw - $menu-end-small - $menu-start);
    }
}

@media screen and (max-width: 480px) {
    .submenu {
        position: fixed;
        top: 7rem;
        left: auto;
        width: 100vw;
        height: 100vh;
    }
}