@import '../../variables';

.menu {
    position: absolute;
    top: calc(100%);
    left: 2rem;
    width: 30rem;
    border: 2px solid $color-mint-dark;
    border-radius: .5rem;

    ul {
        margin: 0;
        padding: 0;
        border-radius: .2rem;
        list-style: none;

        li {
                        
            &:first-child {
                border-radius: .2rem .2rem 0 0;
            }
            
            &:last-child {
                border-radius: 0 0 .2rem .2rem;
                border-bottom: none;
            }
        }

    }

    .menu-item {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 4rem;
        max-width: 100%;
        padding: 1.5rem;
        background-color: white;
        border-bottom: 1px solid $color-mint-dark;
        border-radius: 0;
        font-size: 1.8rem;
        cursor: pointer;
        text-decoration: none;
        transition: all .2s;

        &:hover {
            background-color: $color-mint-dark;
            color: white !important;
        }

        &:visited {
            color: black;
        }

        &--categories {
            position: relative;
        }
    }

    .back-button,
    .close-button {
        display: none;
    }
}

@media screen and (max-width: 650px) {
    .menu {
        width: 20rem;
    }
}

@media screen and (max-width: 480px) {
    .menu {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100%;
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $color-mint-dark;
        border: none;
        box-sizing: border-box;

        .back-button {
            @include icon-button;
            display: inline-block;
            position: absolute;
            top: 0;
            left: 0;

            &-icon {
                @include icon;
            }
        }

        .close-button {
            @include icon-button;
            display: inline-block;
            position: absolute;
            top: 0;
            right: 0;

            &-icon {
                @include icon;
            }

            .delete-path {
                fill: white;
            }
        }

        ul {

            li {
                margin: 4rem 0;
                animation: slideIn  .2s ease-in backwards;

                &:nth-child(2) {
                    animation-delay: .15s;
                }

                &:nth-child(3) {
                    animation-delay: .3s;
                }

                &:nth-child(4) {
                    animation-delay: .45s;
                }
            }
        }
        
        .menu-item {
            font-size: 4rem;
            font-weight: 600;
            color: white;
            border: none;
            background: none;
            cursor: default;

            &:hover {
                background-color: transparent;
                color: white;
            }

            &:visited {
                color: white;
            }
        }
    }
}

@keyframes slideIn {
    from {
        transform: translateX(-100vw);
    }

    to {
        transform: translateX(0);
    }
}