@import '../../variables';

.thought-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    width: 100%;
    background-color: white;
    background-size: cover;
    background-position: center;
    padding: 2rem 3rem;
    margin: 3rem 0;
    border-radius: .5rem;
    box-shadow: -1rem 1rem 1rem rgba(black, .2);
    box-sizing: border-box;

    form {
        display: flex;
        flex-direction: column;
        width: 100%;

        .label {
            font-size: 1.6rem;
            text-shadow: 0 0 .5rem white, 0 0 .5rem white, 0 0 .5rem white;
        }

        input {
            width: 100%;
            background: white;
            padding: 1rem;
            margin: 1rem 0;
            border: none;
            border-radius: .5rem;
            box-sizing: border-box;
        }
    }

    .delete-button {
        position: absolute;
        top: 2rem;
        right: 2rem;
        background: none;
        border: none;
        padding: 0;
        visibility: hidden;
        cursor: pointer;

        .delete-icon {
            width: 2rem;
            height: 2rem;
            padding: .5rem;
        }
    }

    &:hover .delete-button {
        visibility: visible;
    }

    .thought-categories {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: 2rem;

        &-list {
            display: flex;
            flex-wrap: wrap;
            width: 50%;
            font-size: 1.4rem;
            text-shadow: 0 0 .5rem white, 0 0 .5rem white, 0 0 .5rem white;

            .thought-category {
                margin: 1rem;
                cursor: pointer;
                transition: all .3s;

                &:hover {
                    transform: scale(1.1);
                    transform: rotate(-5deg);
                    color: red;
                }
            }
        }
    }

    .addable-categories {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: absolute;
        top: 0;
        right: 0;
        width: 50%;
        height: 100%;
        z-index: 5;
        box-sizing: border-box;
        background-color: white;
        padding: 2rem;
        border: 2px solid $color-mint-dark;
        border-left: 0;
        border-radius: 0 .5rem .5rem 0;
        box-shadow: -2rem 0rem 1rem -1rem rgba(black, .3);

        .addable-categories-list {
            display: flex;
            flex-direction: column;
            overflow: scroll;

            .no-addable-categories {
                color: $color-mint-dark;
                font-size: 2rem;
                text-align: center;
            }
    
            .addable-category {
                display: flex;
                align-items: center;
                margin: .5rem 0;
                font-size: 1.6rem;
            }
        }

        .done-button {
            align-self: center;
            margin-top: 2rem;
        }
    }

    .addable-categories-curtain {
        position: absolute;
        top: 0;
        left: 100%;
        // height is greater than 100% to hide the addable-categories 'done' button's box-shadow if it was being hovered
        height: 110%;
        // 15% / 70% = 21.429% (after width's percentage of screen width / thought item width)
        width: 21.429%;
        z-index: 99;
        background-color: white;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: -1rem;
            z-index: 99;
            height: 2rem;
            width: 1rem;
            background-color: transparent;
            border-radius: 0 .5rem 0 0;
            box-shadow: .5rem 0rem 0 white;
        }

        &::after {
            content: '';
            position: absolute;
            /*
                10% of 110% = 9.09%
                Since addable-categories-curtain is 10% greater than thought-item,
                and we need the bottom of thought-item, not addable-categories-curtain
            */
            bottom: calc(9.09%);
            left: -1rem;
            z-index: 99;
            height: 2rem;
            width: 1rem;
            background-color: transparent;
            border-radius: 0 0 .5rem 0;
            box-shadow: .5rem 0rem 0 white;
        }
    }
}

.slide-in {
    &-enter {
        transform: translateX(100%);
    }
    
    &-enter.slide-in-enter-active {
        transform: translateX(0);
        transition: all .5s ease-in;
    }
    
    &-exit {
        transform: translateX(0);
    }
    
    &-exit.slide-in-exit-active {
        transform: translateX(100%);
        transition: all .5s ease-in;
    }
}

@media screen and (max-width: 1000px) {
    .thought-item {

        .addable-categories-curtain {
            // 10% / 80% = 21.429% (after width's percentage of screen width / thought item width)
            width: 12.5%;
        }
    }
}

@media screen and (max-width: 700px) {
    .thought-item {
        padding: 2rem;

        .addable-categories {
            align-items: center;
            width: 80%;
            border: 2px solid $color-mint-dark;
            border-radius: .5rem;
        }

        .addable-categories-curtain {
            // 5% / 90% = 21.429% (after width's percentage of screen width / thought item width)
            width: 5.556%;
        }
    }
}

@media screen and (max-width: 480px) {
    .thought-item {
        padding: 1rem;

        input {
            width: 50%;
            padding: 1rem;
            margin: 1rem 0;
        }

        .delete-button {
            visibility: visible;
        }

        .thought-categories {
            display: flex;
            flex-direction: column;
            align-items: center;

            &-list {
                align-self: flex-start;
            }

            &-add-button {
                margin: 2rem 0;
            }
        }

        .addable-categories {
            width: 100%;
            border: 2px solid $color-mint-dark;
            border-radius: .5rem;
        }

        .addable-categories-curtain {
            // 5% / 90% = 21.429% (after width's percentage of screen width / thought item width)
            width: 5.556%;
        }
    }
}