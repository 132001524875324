@import '../../variables';

.categories {
    display: flex;
    flex-direction: column;
    height: 50vh;

    &-tabs {
        display: flex;
        height: 4rem;
        color: $color-mint-dark;
        font-weight: 600;
        text-transform: uppercase;
        margin-bottom: 1rem;

        & > * {
            flex-grow: 1;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .background-tab {
            background-color: $color-mint-dark;
            color: white;

            &--select {
                border-radius: 0 0 .8rem 0;
            }
            
            &--edit {
                border-radius: 0 0 0 .8rem;
            }
        }
    }

    &-list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 2rem;
        padding: 2rem;
        overflow: scroll;

        .category-item {
            display: flex;
            align-items: center;
            // .6rem = .5rem padding-bottom + .1rem border-bottom of categories-form-input (used to align select categories and edit categories text)
            padding-bottom: .6rem;
            
            .text{
                font-weight: 400;
                margin: 0;
            }
        }
    }
    
    #categories-form {
        display: grid;
        gap: 2rem;
        grid-template-columns: repeat(3, 1fr);
        padding: 2rem;
        overflow: scroll;
        
        .categories-form-input {
            display: flex;
            // 2.5rem = total width and margin of checkbox (used to align select categories and edit categories text)
            margin-left: 2.5rem;
            padding-bottom: .5rem;
            border-bottom: 1px solid $color-mint-dark;
            width: fit-content;
            
            & > input {
                width: 100%;
                font-size: 1.8rem;
                margin: 0;
                padding: 0;
                border: 0;
            }
        }
    }

    &-buttons {
        display: flex;
        justify-content: center;
        width: 100%;
        margin: 2rem 0;

        .add-category-section {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 100%;
            margin: 0 2rem;

            .cancel-add-button {
                align-self: flex-start;
                background: none;
                color: $color-mint-dark;
                margin-bottom: 2rem;
                border: none;
    
                 &:hover {
                     cursor: pointer;
                 }
            }

            form {
                display: flex;
                align-self: center;
                justify-content: center;
                width: 70%;

                input {
                    height: fit-content;
                    font-size: 1.8rem;
                    margin-right: 4rem;
                    padding: 1rem;
                    border: none;
                    border-bottom: 1px solid $color-mint-dark;
                }
            }
        }
    }

    .confirm-delete {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: white;
        padding: 2rem;
        box-sizing: border-box;

        &-text {
            text-align: center;
            font-size: 2rem;
            margin-bottom: 4rem;
        }

        &-category-name {
            color: $color-mint-dark;
        }

        &-buttons {
            display: flex;
            
            & > button {
                margin: 1rem;
            }
        }
    }
}

@media screen and (max-width: 900px) {
    .categories {

        .categories-list {
            grid-template-columns: repeat(2, 1fr);
        }

        #categories-form {
            grid-template-columns: repeat(2, 1fr);
        }

        .add-category-section {

            form {
                flex-direction: column;
                align-items: center;

                input {
                    width: 100%;
                    margin-bottom: 2rem;
                    margin-right: 0;
                }
            }
        }

        .confirm-delete-buttons {
            flex-direction: column;
        }
    }
}

@media screen and (max-width: 720px) {
    .categories {

        .categories-list {
            display: flex;
            flex-direction: column;
        }

        #categories-form {
            display: flex;
            flex-direction: column;
            
            .categories-form-input {
                // 100% - checkbox width - .categories-form padding
                width: calc(100% - 2.5rem - 2rem);
            }
        }
    }
}

@media screen and (max-width: 480px) {
    .categories {
        height: calc(100vh - 8rem);
        font-size: 1.8rem;
    }
}