@import '../../variables';

.thoughts-filter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    background-color: #f2f2f2;
    margin-top: 3rem;
    padding: 2rem 3rem;
    border-radius: .5rem;
    box-sizing: border-box;

    .contains-all {
        align-self: flex-end;
    }

    .category-filters {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        border-top: 1px solid $color-gray;
    }

    .category {
        display: flex;
        align-items: center;
        font-size: 1.6rem;
        margin: 0rem 2rem;

        input[type=checkbox]{
            margin-right: 1rem;
        }
    }

    .no-categories-message {
        font-size: 1.6rem;
    }
}

@media screen and (max-width: 700px) {
    .thoughts-filter {

        .category-filters {
            grid-template-columns: repeat(3, 1fr);
        }
    }
}

@media screen and (max-width: 550px) {
    .thoughts-filter {
        padding: 1rem;

        .category-filters {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@media screen and (max-width: 480px) {
    .thoughts-filter {

        .category-filters {
            display: flex;
            flex-direction: column;
        }
    }
}