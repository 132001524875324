@import '../../variables';

.button {
    background: none;
    border-radius: 10rem;
    box-shadow: 0rem 1rem 1rem rgba($color: black, $alpha: .2);
    background-color: white;
    border: 2px solid $color-mint-dark;
    color: $color-mint-dark;
    font-size: 2.4rem;
    padding: 1rem 4rem;
    transition: all .3s;
    width: 20rem;
    
    &:hover {
        cursor: pointer;
        transform: translateY(-1rem);
        box-shadow: 0rem 2rem 2rem .25rem rgba($color: black, $alpha: .2);
    }

    &:active {
        transform: translateY(0);
        box-shadow: 0rem 1rem 1rem rgba($color: black, $alpha: .2);
    }
}